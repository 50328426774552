// body {
//   /* fallback for old browsers */
//   background: #6a11cb;

//   /* Chrome 10-25, Safari 5.1-6 */
//   background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));

//   /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
//   background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))
// }

.ant-layout {
  background: url('../../public/background2.png') no-repeat;
}

body {
  background: url('../../public/background2.png') no-repeat;
}

font {
  size: 24px !important;
}

.spin-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}
