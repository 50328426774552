.chartMenu {
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}

.chartIdTitle {
  font-size: 20px;
  margin-top: 20px;
  margin-left: 40px;
}

.diagramCard {
  // margin-left: 360px;
  // margin-top: 300px;
}