.edit-button {
  width: 100%;
}

.delete-button {
  margin-left: "30px";
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row {
  &.ant-table-expanded-row {
    td {
      padding: 8px 16px;
    }
  }
}

.save-button {
  background-color: green !important; /* Set the background color to green */
  border-color: green !important; /* Set the border color to green */
  color: white !important; /* Set the text color to white */
}

.save-button:hover {
  background-color: rgb(0, 168, 0) !important; /* Set the background color to a darker shade of green on hover */
  border-color: rgb(0, 168, 0) !important; /* Set the border color to a darker shade of green on hover */
  color: white !important; /* Set the text color to white */
}

.stackholderInput {
  width: 78;
  vertical-align: 'top';
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}