.language-switcher {
  position: absolute;
  top: 10px;
  right: 10px;
}

.language-option {
  cursor: pointer;
  margin-right: 10px;
  color: white;
}

.language-option.selected {
  font-weight: bold;
  text-decoration: underline;
}
