.totalStatisticCard {
  // background: linear-gradient(70deg, blue, rgb(205, 196, 255));
  // background: blue;
  width: 100%;
  height: 100px;
}
.greenStatisticCard {
  // background: linear-gradient(70deg, green, rgb(190, 255, 195));
  // background: green;
  width: 100%;
  height: 100px;
}
.warnStatisticCard {
  // background: linear-gradient(70deg, yellow, rgb(246, 255, 195));
  // background: yellow;
  width: 100%;
  height: 100px;
}
.redStatisticCard {
  // background: linear-gradient(70deg, red, pink);
  // background: red;
  width: 100%;
  height: 100px;
}
.summaryCard {
  // background: linear-gradient(70deg, red, pink);
  // background: red;
  width: 100%;
  height: 100px;
}
.lineStatisticCard1 {
  width: 100%;
  height: 400px;
}
.aksCard {
  width: 100%;
  height: 920px;
}
.statisticCard {
  width: 100%;
  height: 390px;
}