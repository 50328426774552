// .agreementCheckbox {
//   margin-left: 10px;
// }
.agreementbtn {
  margin-left: -11px;
  // margin-top: 20px;
}

.result {
  margin-top: 20vh;
  // height: 100vh;
  // background-color: white;
}

.registerCard {
  width: 600px;
  margin-left: calc(50% - 300px);
  margin-top: 50px;
}